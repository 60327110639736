
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Actions } from "@/store/enums/StoreEnums";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import { Mutual } from "@/core/model/mutual";
import MutualInfo from "@/views/mutuales/MutualInfo.vue";
import MutualCatalogEditModal from "@/views/mutuales/MutualCatalogEditModal.vue";
import UtilsService from "@/core/services/UtilsService";
import { setCurrentPageActions } from "@/core/helpers/toolbar";

export default defineComponent({
  name: "admin-mutual-catalog",
  components: {
    MutualCatalogEditModal,
    MutualInfo,
    Datatable,
  },
  setup() {
    const tableHeader = ref([
      {
        name: "Marca",
        key: "brand",
        sortable: true,
      },
      {
        name: "Categorías",
        key: "category",
        sortable: false,
        noEnd: true,
      },
      {
        name: "Referencia",
        key: "reference",
        sortable: true,
      },
      {
        name: "Nombre",
        key: "name",
        sortable: true,
      },
      {
        name: "Precio RH",
        key: "pricerh",
        sortable: true,
      },
      {
        name: "Precio",
        key: "price",
        sortable: true,
      },
      {
        name: "Qty.",
        key: "qty",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      },
    ]);
    const loadingProducts = ref<boolean>(false);
    const rowsPerPage = ref(50);
    const currentPage = ref(1);
    const total = ref(0);
    const tableData = ref<Array<Mutual>>([]);
    let sortOrder = "asc";
    let sortField = "name";
    let currentSortColumn = ref("nameasc");
    const search = ref<string>("");

    const loading = ref<boolean>(false);
    const store = useStore();
    const route = useRoute();
    let timerSearch: number | null = null;
    const utils = UtilsService;

    onMounted(() => {
      setCurrentPageActions(false, false, []);
      setCurrentPageBreadcrumbs("Administrar Mutual", ["Mutuales", "Catálogo"]);
      loadProducts();
    });

    //datatable
    const searchItems = () => {
      if (timerSearch) {
        clearTimeout(timerSearch);
        timerSearch = null;
      }
      timerSearch = setTimeout(() => {
        loadProducts();
      }, 800);
    };

    const paginationChangePage = function (page) {
      currentPage.value = page;
      loadProducts();
    };

    const paginationPerPageChange = function (limit) {
      rowsPerPage.value = limit;
      loadProducts();
    };

    const sortList = function (data) {
      sortField = data.columnName;
      sortOrder = data.order;
      currentSortColumn.value = sortField + sortOrder;
      loadProducts();
    };

    const loadProducts = () => {
      const params = {
        page_number: currentPage.value,
        page_size: rowsPerPage.value,
        sort_order: sortOrder,
        sort_field: sortField,
        mutual: route.params.uuid,
        search: search.value,
      };
      loadingProducts.value = true;
      store.dispatch(Actions.ADMIN_PRODUCT_LIST, params).then(() => {
        tableData.value.splice(
          0,
          tableData.value.length,
          ...store.state.AdminProductsModule.list.items
        );
        loadingProducts.value = false;
        rowsPerPage.value = store.state.AdminProductsModule.list.limit;
        currentPage.value = store.state.AdminProductsModule.list.page;
        total.value = store.state.AdminProductsModule.list.total;
      });
    };

    return {
      loading,
      tableData,
      tableHeader,
      rowsPerPage,
      currentPage,
      total,
      paginationChangePage,
      paginationPerPageChange,
      sortList,
      currentSortColumn,
      loadingProducts,
      search,
      searchItems,
      loadProducts,
      utils,
    };
  },
});
